import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const getLink = () => {
  const httpLink = createHttpLink({
    uri: '/v1/graphql',
  })

  const authLink = setContext((_, { headers }) => {
    const jwt = window.location.pathname.slice(1);
    if (jwt.length === 0) {
      throw Error('Missing authorization token')
    }

    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${jwt}`,
      }
    }
  })

  return authLink.concat(httpLink);
}

export const getApolloClient = () => {
  return new ApolloClient({
    link: getLink(),
    cache: new InMemoryCache(),
  })
}
