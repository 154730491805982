import { useQuery } from '@apollo/client';
import { TaskProgressDocument } from '../generated';
import { useLatest } from './latest';

export interface Progress {
  done: number,
  total: number,
}

export const useProgress = (): Progress | undefined => {
  const response = useQuery(TaskProgressDocument);
  const data = useLatest(response.data);
  if (data?.done.aggregate && data.total.aggregate) {
    return {
      done: data.done.aggregate?.count,
      total: data.total.aggregate?.count,
    }
  } else {
    return undefined;
  }
}