import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Worker } from './components/Worker';

function App() {
  return (
    <Routes>
      <Route path="/:jwt" element={<Worker />} />
    </Routes>
  );
}

export default App;
