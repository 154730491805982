import { CheckCircleIcon } from '@heroicons/react/outline';
import React from 'react';
import { XIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

export interface NotificationProps {
  type: 'success',
  onDismiss?: () => void,
  children: React.ReactNode,
  className?: string,
}

export const Notification = ({onDismiss, children, className}: NotificationProps) => (
  <div className={classNames("flex gap-4 shadow-lg border border-gray-200 rounded-lg px-6 py-4 bg-white", className)}>
    <CheckCircleIcon className="w-5 h-5 text-green-600 mt-0.5" />
    <div className="flex-1">{children}</div>
    {onDismiss && <XIcon className="w-5 h-5 text-gray-400 mt-0.5 cursor-pointer" /> }
  </div>
)