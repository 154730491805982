export interface TextareaProps {
  placeholder: string
  value: string
  onChange: (value: string) => void,
}

export const Textarea = ({placeholder, value, onChange}: TextareaProps) => {
  return (
    <textarea
      placeholder={placeholder}
      className="w-full border border-gray-200 rounded-md py-2 px-3 outline-teal-600"
      value={value}
      onChange={ev => onChange(ev.target.value)}
    />
  )
}