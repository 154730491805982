import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import classNames from 'classnames';
import { range } from 'lodash';


export interface PaginationProps {
  total: number;
  current: number;
  onChange: (page: number) => void;
}

export const Pagination = ({total, current, onChange}: PaginationProps) => {
  const rollingFrom = current <= 3 ? 1 : current - 1;
  const rollingTo = Math.min(current + 1, total);

  return (
    <div className="py-3 items-center border-gray-200 text-center">
      <nav className="z-0 inline-flex shadow-sm -space-x-px">
        <PaginationItem content='previous' isFirst onClick={current > 1 ? () => onChange(current - 1) : undefined} />

        {rollingFrom > 1 && <>
          <PaginationItem content={1} onClick={() => onChange(1)} />
          <PaginationItem content='ellipsis' />
        </>}

        {range(rollingFrom, rollingTo + 1).map(p =>
          <PaginationItem key={p} content={p} onClick={() => onChange(p)} isCurrent={current === p} />
        )}

        {rollingTo < total && <PaginationItem content='ellipsis' />}

        <PaginationItem content='next' isLast onClick={current < total ? () => onChange(current + 1) : undefined} />
      </nav>
    </div>
  )
}

interface PaginationItemProps {
  content: number | 'previous' | 'ellipsis' | 'next',
  isCurrent?: boolean,
  onClick?: () => void,
  isFirst?: boolean,
  isLast?: boolean,
}

const PaginationItem = ({content, isCurrent, onClick, isFirst, isLast}: PaginationItemProps) => (
  <a
    href={onClick ? '#' : undefined}
    onClick={(event) => {
      event.preventDefault();
      if (onClick) onClick();
    }}
    className={classNames(
      (content === 'previous' || content === 'next') ? 'px-2' : 'px-4',
      isCurrent ? 'z-10 bg-teal-50 border-teal-600 text-teal-700' : 'bg-white border-gray-300 text-gray-500',
      !isCurrent && onClick && 'hover:bg-gray-50',
      'relative inline-flex items-center py-2 border text-sm font-medium',
      isFirst && 'rounded-l-md',
      isLast && 'rounded-r-md',
    )}
  >
    {content === 'previous' && <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" /> }
    {content === 'next' && <ChevronRightIcon className="h-5 w-5" aria-hidden="true" /> }
    {content === 'ellipsis' && '...'}
    {typeof content === 'number' && `${content}`}
  </a>
)
