import classNames from 'classnames';
import React from 'react';

export interface ButtonProps {
  onClick?: () => void,
  disabled?: boolean,
  children: React.ReactNode,
}

export const Button = ({ children, onClick, ...rest }: ButtonProps) => {
  const disabled = rest.disabled ?? !onClick;

  return (
    <button
      type="button"
      onClick={disabled ? undefined : onClick}
      className={classNames(
        disabled ? 'bg-gray-300 text-black' : 'bg-teal-700 hover:bg-teal-800 text-white',
          'rounded-md px-5 py-1.5 ml-auto block mt-10 shadow-sm'
      )}
    >
      {children}
    </button>
  )
}
