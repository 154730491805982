import { Pagination } from './Pagination';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { TopicResponsesDocument } from '../generated';
import { XIcon } from '@heroicons/react/outline';
import { useLatest } from '../hooks/latest';
import classNames from 'classnames';
import { ScoreInfoButton } from './ScoreInfoButton';
import { ShowMore } from './ShowMore';


export interface TopicResponsesProps {
  topicId: string;
  word: string | null;
  onClearWord: () => void;
}

export const TopicResponses = ({topicId, word, onClearWord}: TopicResponsesProps) => {
  const [page, setPage] = useState(1);
  const pageSize = 10;

  useEffect(() => setPage(1), [word]);

  const {data} = useQuery(TopicResponsesDocument, {
    variables: {
      topic_id: topicId,
      offset: (page - 1) * pageSize,
      limit: pageSize,
      where: !word ? undefined : {
        response: {
          response_words: {
            word: {_eq: word},
          }
        }
      }
    },
  });

  const latestData = useLatest(data);

  if (latestData === undefined) {
    return <span>Loading</span>
  }

  const assignments = latestData.topics_by_pk?.response_assignments ?? [];
  const totalAssignments = latestData.topics_by_pk?.response_assignments_aggregate.aggregate?.totalCount ?? 0;
  const totalPages = Math.ceil(totalAssignments / pageSize);

  return <>
    <h2 className="text-xl mb-5">
      Freitextantworten zu dem Thema
      {word && <>
          {' '}
          mit {word}
          <XIcon className="w-4 h-4 cursor-pointer inline-block text-gray-800 ml-1 align-middle" onClick={onClearWord} />
      </>}
    </h2>

    <table className="w-full border-t border-b border-gray-300">
      <thead>
      <tr>
        <Cell isHeader className="whitespace-nowrap">
          Zuweisung
          <ScoreInfoButton className="inline-block pl-1" />
        </Cell>
        <Cell isHeader className="text-left w-full">Freitextantwort</Cell>
      </tr>
      </thead>
      <tbody>
        {assignments.map(assignment => (
          <tr key={`${topicId}/${assignment.response.id}`}>
            <Cell className="text-right font-semibold">{formatScore(assignment.score)}</Cell>
            <Cell>
              <ShowMore
                text={assignment.response.content}
                maxLines={5}
              />
            </Cell>
          </tr>
        ))}
      </tbody>
    </table>
    <div>
    </div>

    <Pagination current={page} total={totalPages} onChange={setPage} />
  </>
}

const formatScore = (score: number) => (score * 100).toFixed(0);


interface CellProps {
  isHeader?: boolean,
  className?: string,
  children: React.ReactNode,
}

const Cell = ({isHeader, className, children}: CellProps) => {
  const cl = classNames(
    "py-3 px-2",
    !isHeader && "border-t border-gray-100",
    className
  );

  return isHeader
    ? <th className={cl}>{children}</th>
    : <td className={cl}>{children}</td>;
}
