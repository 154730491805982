import { useQuery } from '@apollo/client';
import { NextTaskDocument } from '../generated';
import { TaskForm } from './TaskForm';
import { useState } from 'react';
import { SaveNotification } from './SaveNotification';
import { useUserId } from '../hooks/userId';
import { Alert } from './Alert';
import { useProgress } from '../hooks/progress';

export const Worker = () => {
  const userId = useUserId();
  const progress = useProgress();
  const queryResult = useQuery(NextTaskDocument, {
    variables: { userId },
  });

  const [isSaveNotificationVisible, setIsSaveNotificationVisible] = useState(false);

  const task = queryResult.data?.tasks[0];

  return (
    <div className="bg-gray-50">
      <div className='min-h-screen mx-auto pt-20 pb-36 px-20 bg-white' style={{width: '50rem'}}>
        <span className="uppercase text-teal-700 font-semibold">
          evainsights Piloten Evaluation
          {progress && progress.total > 0 && ` ${progress.done+1}/${progress.total}`}
        </span>
        <h1 className="text-3xl font-bold">Themenbewertung</h1>

        <SaveNotification isVisible={isSaveNotificationVisible} />

        {queryResult.error ? (
          <Alert level="error" className="mt-16 mx-16">
            Beim Laden des nächsten Themas ist ein Fehler aufgetreten <br />
            <code>{queryResult.error.message}</code>
          </Alert>
        ) : !queryResult.loading && (progress?.total === 0 ? (
          <Alert level="info" className="mt-16 mx-16">
            Ihnen wurden noch keine Themen zum Bewerten zugewiesen
          </Alert>
        ) : (!task ? (
          <Alert level="success" className="mt-16 mx-16">
            <p className="font-bold mb-1">Alles geschafft!</p>
            Vielen Dank für Ihre Teilnahme
          </Alert>
        ) : (
          <TaskForm
            key={`${task.user.id}/${task.topic.id}`}
            task={task}
            onSaved={() => {
              setIsSaveNotificationVisible(true);
              window.setTimeout(() => setIsSaveNotificationVisible(false), 1500);
            }}
          />
        )))}
      </div>
    </div>
  )
}
