import React from 'react';
import { CheckCircleIcon, InformationCircleIcon, XCircleIcon, XIcon } from '@heroicons/react/solid'
import classNames from 'classnames';

export interface AlertProps {
  level: 'success' | 'info' | 'error',
  children: React.ReactNode,
  className?: string,
  onDismiss?: () => void,
}

export const Alert = ({level, children, className, onDismiss}: AlertProps) => (
  <div className={classNames(
    level === 'success' && 'bg-green-50 text-green-600',
    level === 'info' && 'bg-sky-50 text-sky-600',
    level === 'error' && 'bg-red-50 text-red-600',
    'rounded-md px-6 py-4 flex gap-3',
    className)
  }>
    {level === 'success' && <CheckCircleIcon className="h-5 w-5 mt-0.5 text-green-500" aria-hidden="true" />}
    {level === 'info' && <InformationCircleIcon className="h-5 w-5 mt-0.5 text-sky-500" aria-hidden="true" />}
    {level === 'error' && <XCircleIcon className="h-5 w-5 mt-0.5 text-red-500" aria-hidden="true" />}

    <div className="flex-1">
      {children}
    </div>
    {onDismiss && <XIcon className="h-5 w-5 mt-0.5 cursor-pointer" onClick={onDismiss} /> }
  </div>
)
