import { useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { JwtPayload } from '../types/auth';

export const useUserId = (): string => {
  const { jwt } = useParams<'jwt'>();
  if (!jwt) {
    throw Error('Token is required')
  }

  const jwtBody = jwtDecode<JwtPayload>(jwt);
  return jwtBody['user-id'];
}