import { Popover } from "@headlessui/react"
import { InformationCircleIcon } from '@heroicons/react/outline';
import React from 'react';
import classNames from 'classnames';

export interface ScoreInfoButtonProps {
  className?: string;
}

export const ScoreInfoButton = ({className}: ScoreInfoButtonProps) => {
  return (
    <Popover className={classNames("relative", className)}>
      <Popover.Button>
        <InformationCircleIcon className="w-5 h-5 inline-block text-gray-600" />
      </Popover.Button>

      <Popover.Panel className="absolute z-20 bg-white px-5 py-3 rounded-md border border-gray-200 -ml-5 mt-2 font-normal whitespace-normal text-left shadow-md" style={{width: '25rem'}}>
        Der Zuweisungswert beschreibt, <strong>wie sicher</strong> der Algorithmus die Freitextantwort dem Thema zuweist.
        Bei einem Wert nahe 100 ist sich der Algorithmus sicher, dass die Freitextantwort gut zu dem Thema passt.
        Bei einem niedrigen Wert hingegen würde der Algorithmus die Freitextantwort eher anderen Themen zuweisen.
      </Popover.Panel>
    </Popover>
  )
}

