import { Wordcloud } from '@visx/wordcloud';
import { Text } from '@visx/text';
import { useEffect, useMemo, useState } from 'react';
import { scaleLog } from '@visx/scale';
import { BaseDatum } from '@visx/wordcloud/lib/types';
import chroma from 'chroma-js';

export interface Keyword {
  word: string,
  weight: number,
  occurrences: number,
}

export interface TopicWordcloudProps {
  keywords: Keyword[],
  onClickWord?: (word: string) => void,
}

export const TopicWordcloud = ({keywords, onClickWord}: TopicWordcloudProps) => {
  const words: Word[] = useMemo(() => keywords.map(keyword => ({
    text: keyword.word,
    importance: keyword.weight,
    occurrences: keyword.occurrences,
  })), [keywords]);

  const getFontSize = useMemo(() => {
    const logOffset = 0.05;

    const importanceScale = scaleLog({
      domain: getDomain(words.map((w) => w.importance + logOffset)),
      range: [15, 45],
    });

    return (word: Word) => importanceScale(word.importance + logOffset);
  }, [words]);

  const getFill = useMemo(() => {
    const logOffset = 1;

    const colorScale = chroma.scale('Blues');
    const occurrenceScale = scaleLog({
      domain: getDomain(words.map((w) => w.occurrences + logOffset)),
      range: [0.2, 1],
    });

    return (word: Word) => colorScale(occurrenceScale(word.occurrences + logOffset)).hex();
  }, [words]);

  const [isFontLoaded, setIsFontLoaded] = useState(checkFont());
  useEffect(() => {
    if (!isFontLoaded) {
      const checkIntervalId = setInterval(() => {
        if (checkFont()) {
          clearInterval(checkIntervalId);
          setIsFontLoaded(true);
        }
      }, 100);
    }
  }, []);

  if (!isFontLoaded) {
    // add a text element that triggers fetching the required font face
    return <span style={{
      fontFamily: font.family,
      fontWeight: font.weight,
    }}>Loading…</span>
  }

  return (
    <Wordcloud
      width={600}
      height={300}
      words={words}
      fontSize={getFontSize}
      font={font.family}
      fontWeight={font.weight}
      padding={2}
      spiral={'archimedean'}
      random={fixedValueGenerator}
      rotate={0}
    >
      {(cloudWords) =>
        cloudWords.map((w) => (
          <Text
            key={w.text}
            fill={getFill(w as any)}
            textAnchor={'middle'}
            transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
            fontSize={w.size}
            fontFamily={w.font}
            fontWeight={w.weight}
            cursor={onClickWord ? 'pointer' : 'default'}
            onClick={() => {
              if (w.text && onClickWord) {
                onClickWord(w.text);
              }
            }}
            style={{userSelect: 'none'}}
          >
            {w.text}
          </Text>
        ))
      }
    </Wordcloud>
  )
}
interface Word extends BaseDatum {
  importance: number,
  occurrences: number,
}

const fixedValueGenerator = () => 0.5;

const getDomain = (values: number[]): [number, number] => ([
  Math.min(...values),
  Math.max(...values)
])

const font = {
  family: 'Inter',
  weight: 600,
}

const checkFont = () => {
  return document.fonts.check(`${font.weight} 12px ${font.family}`);
}
