import classNames from 'classnames';
import React from 'react';

export interface FormControlLabelProps {
  className?: string,
  hasError?: boolean,
  text: string,
}

export const FormControlLabel = ({hasError, text, className}: FormControlLabelProps) => {
  return (
    <div className={classNames("mb-2", className)}>
      <span className={classNames(
        "font-semibold",
        hasError && "text-red-600",
      )}>{text}</span>
      {hasError === undefined && (
        <span className="text-gray-400 inline-block ml-3">Optional</span>
      )}
    </div>
  )
}
