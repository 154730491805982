import React, { useMemo, useState } from 'react';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import { escape } from 'lodash';

export interface ShowMoreProps {
  text: string;
  maxLines: number;
}

export const ShowMore = ({text: unsafeText, maxLines}: ShowMoreProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isClamped, setIsClamped] = useState(false);
  const text = useMemo(() => escape(unsafeText), [unsafeText]);

  return (
    <HTMLEllipsis
      unsafeHTML={
        isClamped && isExpanded
          ? text + ' <i class="text-sky-700 cursor-pointer" data-show-less>Weniger anzeigen</i>'
          : text
      }
      maxLine={isExpanded ? Number.POSITIVE_INFINITY : maxLines}
      onReflow={({clamped}) => !isExpanded && setIsClamped(clamped)}
      ellipsisHTML='... <i class="text-sky-700 cursor-pointer" data-show-more>Mehr anzeigen</i>'
      onClick={(ev) => {
        if ((ev.target as HTMLElement).dataset['showMore'] !== undefined) {
          setIsExpanded(true);
        } else if ((ev.target as HTMLElement).dataset['showLess'] !== undefined) {
          setIsExpanded(false);
        }
      }}
    />
  )
}
