import { Rating } from './Rating';
import { Button } from './Button';
import { FormControlLabel } from './FormControlLabel';
import { useState } from 'react';
import { Textarea } from './Textarea';

export interface RateResponsesProps {
  responsesRating: number | null;
  onChangeResponsesRating: (rating: number) => void;
  matchRating: number | null;
  onChangeMatchRating: (rating: number) => void;
  note: string;
  onChangeNote: (note: string) => void;
  onSubmit: () => void;
}

export const RateResponses = (props: RateResponsesProps) => {
  const [submitted, setSubmitted] = useState(false);

  return <>
    <FormControlLabel
      text="Wie gut verstehen Sie das durch die Freitextantworten gegebene Thema?"
      hasError={submitted && !props.responsesRating}
      className="mt-12"
    />
    <Rating id="responses" value={props.responsesRating} onChange={props.onChangeResponsesRating} lowLabel='schlecht' highLabel='gut' />

    <FormControlLabel
      text="Wie gut passen die Freitextantworten zu dem Thema der Wortwolke?"
      hasError={submitted && !props.matchRating}
      className="mt-7"
    />
    <Rating id="match" value={props.matchRating} onChange={props.onChangeMatchRating} lowLabel='schlecht' highLabel='gut' />

    <FormControlLabel
      text="Haben Sie Anmerkungen zu den Freitextantworten?"
      className="mt-7"
    />
    <Textarea placeholder="Anmerkungen" value={props.note} onChange={props.onChangeNote} />

    <Button onClick={() => {
      setSubmitted(true);
      if (props.responsesRating !== null && props.matchRating !== null) {
        props.onSubmit();
      }
    }}>Submit</Button>
  </>
}
