import { range } from 'lodash';

export interface RatingProps {
  id: string;
  value: number | null;
  onChange: (value: number) => void;
  lowLabel: string;
  highLabel: string;
}

export const Rating = ({id, value, onChange, lowLabel, highLabel}: RatingProps) => {
  return (
    <div>
      <div className="flex gap-2 mb-1">
        {range(1, 7).map(option => (
          <div className="flex-1" key={option}>
            <input type="radio" name={id} id={`${id}-${option}`} onChange={() => onChange(option)} checked={value === option} className="peer hidden" />
            <label htmlFor={`${id}-${option}`} className="block text-center flex-1 px-5 py-1 rounded-md border border-gray-200 shadow-sm hover:bg-gray-50 peer-checked:text-white peer-checked:border-transparent peer-checked:bg-teal-700 cursor-pointer">
              {option}
            </label>
          </div>
        ))}
      </div>
      <div className="flex justify-between text-gray-500">
        <span>{lowLabel}</span>
        <span>{highLabel}</span>
      </div>
    </div>
  )
}