import { NextTaskDocument, SaveRatingDocument, TaskProgressDocument } from '../generated';
import { Step } from '../types/Step';
import { Keyword, TopicWordcloud } from './Wordcloud';
import { RateWordcloud } from './RateWordcloud';
import { Alert } from './Alert';
import { TopicResponses } from './TopicResponses';
import { RateResponses } from './RateResponses';
import { useMutation } from '@apollo/client';
import { useState } from 'react';


export interface TopicRatingProps {
  onSaved: () => void,
  task: {
    user: {
      id: string,
    },
    topic: {
      id: string,
      keywords: Keyword[],
    }
  }
}

export const TaskForm = ({task, onSaved}: TopicRatingProps) => {
  const [saveRatingMutation] = useMutation(SaveRatingDocument, {
    refetchQueries: [NextTaskDocument, TaskProgressDocument],
  });

  const [step, setStep] = useState(Step.RATE_WORDCLOUD);
  const [wordcloudRating, setWordcloudRating] = useState<number | null>(null);
  const [topicName, setTopicName] = useState('');
  const [wordcloudNote, setWordcloudNote] = useState('');
  const [responsesRating, setResponsesRating] = useState<number | null>(null);
  const [matchRating, setMatchRating] = useState<number | null>(null);
  const [responsesNote, setResponsesNote] = useState('');

  const [searchWord, setSearchWord] = useState<null | string>(null);

  return <>
    <div className="flex justify-center mt-12 mb-8">
      <TopicWordcloud
        keywords={task.topic.keywords}
        onClickWord={step !== Step.RATE_RESPONSES ? undefined : (word) => {
          if (word !== searchWord) setSearchWord(word);
          else setSearchWord(null);
        }}
      />
    </div>

    {step === Step.RATE_WORDCLOUD && (
      <RateWordcloud
        rating={wordcloudRating}
        onChangeRating={setWordcloudRating}
        topicName={topicName}
        onChangeTopicName={setTopicName}
        note={wordcloudNote}
        onChangeNote={setWordcloudNote}
        onConfirm={() => setStep(Step.RATE_RESPONSES)}
      />
    )}

    {step === Step.RATE_RESPONSES && <>
        <Alert level="info" className="mb-8">
            Lesen Sie die unten stehenden Freitextantworten und bewerten Sie diese anschließend.
            Sie können einzelne Wörter in der Wortwolke anklicken um die Liste der Freitextantworten nach diesem Wort zu durchsuchen.
        </Alert>

        <TopicResponses topicId={task.topic.id} word={searchWord} onClearWord={() => setSearchWord(null)} />

        <RateResponses
            responsesRating={responsesRating}
            onChangeResponsesRating={setResponsesRating}
            matchRating={matchRating}
            onChangeMatchRating={setMatchRating}
            note={responsesNote}
            onChangeNote={setResponsesNote}
            onSubmit={() => {
              if (!wordcloudRating || !responsesRating || !matchRating) {
                throw Error('Tried to save a rating with missing fields');
              }

              saveRatingMutation({
                variables: {
                  topic_id: task.topic.id,
                  user_id: task.user.id,
                  wordcloud_rating: wordcloudRating,
                  topic_name: topicName,
                  wordcloud_notes: wordcloudNote,
                  responses_rating: responsesRating,
                  responses_notes: responsesNote,
                  match_rating: matchRating,
                }
              }).then(onSaved);
            }}
        />
    </>}
  </>
}
