import { Rating } from './Rating';
import { Button } from './Button';
import { useState } from 'react';
import { FormControlLabel } from './FormControlLabel';
import { Textarea } from './Textarea';

export interface RateWordcloudProps {
  rating: number | null;
  onChangeRating: (rating: number) => void,
  topicName: string;
  onChangeTopicName: (name: string) => void,
  note: string;
  onChangeNote: (note: string) => void,
  onConfirm: () => void,
}

export const RateWordcloud = ({rating, onChangeRating, topicName, onChangeTopicName, note, onChangeNote, onConfirm}: RateWordcloudProps) => {
  const [submitted, setSubmitted] = useState(false);

  return <>
    <FormControlLabel
      text="Wie gut denken Sie, können Sie anhand der Worte verstehen, um welches Thema es geht?"
      hasError={submitted && !rating}
    />
    <Rating id="wordcloud" value={rating} onChange={onChangeRating} lowLabel='schlecht' highLabel='gut' />

    <FormControlLabel
      text="Wie würden Sie das Thema benennen?"
      hasError={submitted && !topicName}
      className="mt-7"
    />
    <input
      type="text"
      placeholder="Name"
      value={topicName}
      onChange={ev => onChangeTopicName(ev.target.value)}
      className="w-full border border-gray-200 rounded-md py-2 px-3 outline-teal-600"
    />

    <FormControlLabel
      text="Haben Sie Anmerkungen zu der Wortwolke?"
      className="mt-7"
    />
    <Textarea placeholder="Anmerkungen" value={note} onChange={onChangeNote} />

    <Button
      onClick={() => {
        setSubmitted(true);
        if (rating !== null && topicName !== '') {
          onConfirm()
        }
      }}
    >
      Continue
    </Button>
  </>
}