import { animated, useSpring } from 'react-spring';
import { Notification } from './Notification';

export interface SaveNotificationProps {
  isVisible: boolean;
}

export const SaveNotification = ({isVisible}: SaveNotificationProps) => {
  const style = useSpring({
    to: {
      top: isVisible ? 35: 5,
      opacity: isVisible ? 1 : 0,
    },
    config: {duration: 200}
  });

  return (
    <animated.div style={style} className="absolute right-14">
      <Notification type='success'>
        <span className="font-semibold">Erfolgreich gespeichert</span> <br/>
        <span className="text-gray-400">Fortsetzung mit dem nächsten Thema.</span>
      </Notification>
    </animated.div>
  )

}